import './main.scss';
import React, {useState} from 'react';
import {Navigate, Link} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import {useTranslation} from 'react-i18next';

import Footer from "../../../organisms/footer/footer";
import cotedAzurGallery from "../../../../assets/img/experiences/cotedAzur-gallery.jpg";
import MykonosGallery from "../../../../assets/img/experiences/mykonos-gallery.jpg";

import HahnenkammGallery from "../../../../assets/img/experiences/hahnenkamm-gallery.jpg";
import AlpineEscapeGallery from "../../../../assets/img/experiences/alpine-escape-gallery.jpg";
import IslandEscapeMykonosGallery from "../../../../assets/img/experiences/island-escape-mykonos-gallery.jpg";
import BordeauxGallery from "../../../../assets/img/experiences/bordeaux-gallery.jpg";
import ChampagnevisitGallery from "../../../../assets/img/experiences/champagnevisit-gallery.jpg";
import CulturalcuratorGallery from "../../../../assets/img/experiences/culturalcurator-gallery.jpg";
import InsidetrackGallery from "../../../../assets/img/experiences/indise-track-gallery.jpg";
import EastnorGallery from "../../../../assets/img/experiences/eastnor-gallery.jpg";


const HacExperienceCatalog = () => {
  window.scroll(0, 0);

  const {t} = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const [activeTab, setActiveTab] = useState('new');
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };



  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header/>
          <main className="landing">
            <Wadsworth/>
            <section className="gallery catalog">
              <div className="inner">
                <h2>{t('hac-landing-gallery-title')}</h2>
                <p>{t('hac-landing-gallery-text')}</p>

                <div className="tabs internal">
                  <ul>
                    <li className={activeTab === 'past' ? 'active' : ''}>
                      <span onClick={() => handleTabClick('past')}>{t('experiences-old')}</span>
                    </li>
                    <li className={activeTab === 'new' ? 'active' : ''}>
                      <span onClick={() => handleTabClick('new')}>{t('experiences-new')}</span>
                    </li>
                  </ul>
                </div>

                {activeTab === 'past' && (
                  <div className="past-experiences">
                    <ul>
                      <li>
                        <img src={cotedAzurGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-cotedAzur')}: </strong>{t('hac-landing-gallery-text-cotedAzur')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `cotedAzur` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={MykonosGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-mykonos')}: </strong>
                          {t('hac-landing-gallery-text-mykonos')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `mykonos` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                    </ul>

                  </div>
                )}

                {activeTab === 'new' && (
                  <div className="new-experiences">
                    <ul>
                      <li>
                        <img src={HahnenkammGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-hahnenkamm')}: </strong>
                          <strong>{t('hac-landing-gallery-date-hahnenkamm')}</strong>
                          {t('hac-landing-gallery-text-hahnenkamm')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `hahnenkamm` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={AlpineEscapeGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-alpine-escape')}: </strong>
                          <strong>{t('hac-landing-gallery-date-alpine-escape')}</strong>
                          {t('hac-landing-gallery-text-alpine-escape')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `alpine-escape` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={IslandEscapeMykonosGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-island-escape-mykonos')}: </strong>
                          <strong>{t('hac-landing-gallery-date-island-escape-mykonos')}</strong>
                          {t('hac-landing-gallery-text-island-escape-mykonos')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `island-escape-mykonos` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={BordeauxGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-bordeaux')}: </strong>
                          <strong>{t('hac-landing-gallery-date-bordeaux')}</strong>
                          {t('hac-landing-gallery-text-bordeaux')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `bordeaux` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={ChampagnevisitGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-champagnevisit')}: </strong>
                          <strong>{t('hac-landing-gallery-date-champagnevisit')}</strong>
                          {t('hac-landing-gallery-text-champagnevisit')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `champagnevisit` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={CulturalcuratorGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-culturalcurator')}: </strong>
                          <strong>{t('hac-landing-gallery-date-culturalcurator')}</strong>
                          {t('hac-landing-gallery-text-culturalcurator')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `culturalcurator` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={InsidetrackGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-inside-track')}: </strong>
                          <strong>{t('hac-landing-gallery-date-inside-track')}</strong>
                          {t('hac-landing-gallery-text-inside-track')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `inside-track` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                      <li>
                        <img src={EastnorGallery} alt="" />
                        <p>
                          <strong>{t('hac-landing-gallery-title-eastnor')}: </strong>
                          <strong>{t('hac-landing-gallery-date-eastnor')}</strong>
                          {t('hac-landing-gallery-text-eastnor')}
                        </p>
                        <p>
                          <Link
                            to={`/hac/experience`}
                            state={{ event: `eastnor` }}
                            className="link"
                          ><span className="icon-chevron-right"></span> {t('experiences-catalog-cta')}</Link>
                        </p>
                      </li>
                    </ul>

                  </div>
                )}


              </div>
            </section>

          </main>
          <Footer/>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}

    </>
  );
};

export default HacExperienceCatalog;
