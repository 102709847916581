import './retailer-info.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from '../../../../utils/apiClient';
import { Alert, Snackbar } from '@mui/material';

function RetailerInfo() {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const { id } = useParams();

  const [contactInfo, setContactInfo] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const fetchData = async () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/pma/contact-info/hac/${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.get(url, config);
      if (response.status === 200) {
        setContactInfo(response.data);
      }
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('alert-server-error'), error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {token && role === 'pma' ? (
        <div className="App">
          <Header />
          <main>
            <section className="contact-info">
              <div className="inner">
                <Link to="/pma/main" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                <h2>{t('retailer-info-title')}</h2>
                {contactInfo ? (
                  <>
                    <div className="retailer">
                      <h5>{t('retailer-info-title')}</h5>
                      <dl>
                        <div>
                          <dt>{t('common-name')}</dt>
                          <dd>{contactInfo.retailer.name}</dd>
                        </div>
                        <div>
                          <dt>{t('common-country')}</dt>
                          <dd>{t(`countries-${contactInfo.retailer.area_code}`)}</dd>
                        </div>
                        <div>
                          <dt>{t('common-city')}</dt>
                          <dd>{contactInfo.retailer.city}</dd>
                        </div>
                        <div>
                          <dt>{t('common-retailer-code')}</dt>
                          <dd>{contactInfo.retailer.retailer_code}</dd>
                        </div>
                      </dl>
                    </div>

                    {contactInfo.retailer_gm && (
                      <div className="person">
                        <h5>{t('retailer-info-title-retailergm')}</h5>
                        <dl>
                          <div>
                            <dt>{t('common-name')}</dt>
                            <dd>
                              {contactInfo.retailer_gm.name}{' '}
                              {contactInfo.retailer_gm.last_name}
                            </dd>
                          </div>
                          <div>
                            <dt>{t('common-email')}</dt>
                            <dd>{contactInfo.retailer_gm.email}</dd>
                          </div>
                          <div>
                            <dt>{t('common-phone')}</dt>
                            <dd>
                              {contactInfo.retailer_gm.prefix_phone}{' '}
                              {contactInfo.retailer_gm.phone}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    )}

                    {contactInfo.first_sales_person && (
                      <div className="person">
                        <h5>{t('retailer-info-title-saleperson1')}</h5>
                        <dl>
                          <div>
                            <dt>{t('common-name')}</dt>
                            <dd>
                              {contactInfo.first_sales_person.name}{' '}
                              {contactInfo.first_sales_person.last_name}
                            </dd>
                          </div>
                          <div>
                            <dt>{t('common-email')}</dt>
                            <dd>{contactInfo.first_sales_person.email}</dd>
                          </div>
                          <div>
                            <dt>{t('common-phone')}</dt>
                            <dd>
                              {contactInfo.first_sales_person.prefix_phone}{' '}
                              {contactInfo.first_sales_person.phone}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    )}
                    {contactInfo.second_sales_person && (
                      <div className="person">
                        <h5>{t('retailer-info-title-saleperson2')}</h5>
                        <dl>
                          <div>
                            <dt>{t('common-name')}</dt>
                            <dd>
                              {contactInfo.second_sales_person.name || 'N/A'}{' '}
                              {contactInfo.second_sales_person.last_name || ''}
                            </dd>
                          </div>
                          <div>
                            <dt>{t('common-email')}</dt>
                            <dd>
                              {contactInfo.second_sales_person.email || 'N/A'}
                            </dd>
                          </div>
                          <div>
                            <dt>{t('common-phone')}</dt>
                            <dd>
                              {contactInfo.second_sales_person.prefix_phone || 'N/A'}{' '}
                              {contactInfo.second_sales_person.phone || ''}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    )}
                  </>
                ) : (
                  <div>{t('common-loading') + '...'}</div>
                )}
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default RetailerInfo;
