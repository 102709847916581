import './vehicles.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import apiClient from '../../../../utils/apiClient';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';

function HacEditVehicle() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const location = useLocation();
  const vehicle = location.state?.vehicle || {};
  const hacId = location.state?.hacId || '';

  const [purchaseDate, setPurchaseDate] = useState(vehicle.purchase_date || '');
  const { control } = useForm();

  const navigate = useNavigate();

  const [id] = useState(vehicle.id || '');
  const [model, setModel] = useState(vehicle.model || '');
  const [licensePlate, setLicensePlate] = useState(vehicle.license_plate || '');
  const [vistaNumber] = useState(vehicle.vista_number || '');

  const [vin, setVin] = useState(vehicle.vin || '');
  const [retailerAreaCode, setRetailerAreaCode] = useState(
    vehicle.retailer_area_code || ''
  );
  const [retailerCity, setRetailerCity] = useState(vehicle.retailer_city || '');
  const [retailerId, setRetailerId] = useState(vehicle.retailer_id || '');
  const [otherModel, setOtherModel] = useState('');
  const [showOther, setShowOther] = useState(false);

  const [retailers, setRetailers] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [citySearch, setCitySearch] = useState('');

  const [models, setModels] = useState([]);
  const [uniqueCountries, setUniqueCountries] = useState([]);

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!model) newErrors.model = 'Please select a model.';
    if (model === 'Other' && !otherModel)
      newErrors.other = 'Other model field is required';
    if (
      licensePlate &&
      (licensePlate.length > 12 || !/^[\w\s-·]+$/.test(licensePlate))
    ) {
      newErrors.licensePlate =
        'License plate must be down to 12 characters long and can only include alphanumeric characters, spaces, - and ·.';
    }
    if (vin && (vin.length > 20 || !/^[a-zA-Z0-9]+$/.test(vin))) {
      newErrors.vin =
        'VIN must be down to 20 characters long and can only include alphanumeric characters.';
    }

/*    if (retailerAreaCode || retailerCity || retailerId) {
      if (!retailerAreaCode)
        newErrors.retailerAreaCode =
          'Country is required if city or retailer name is provided.';
      if (!retailerCity)
        newErrors.retailerCity =
          'City is required if country or retailer name is provided.';
      if (!retailerId)
        newErrors.retailerId =
          'Retailer name is required if country or city is provided.';
    }*/

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/retailer/all/active`,
          config
        );
        setRetailers(response.data);

        const uniqueCountries = Array.from(
          new Set(response.data.map((retailer) => retailer.area_code))
        );
        setUniqueCountries(uniqueCountries);
      } catch (error) {
        console.error('Error fetching retailers:', error);
      }
    };

    const fetchModels = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/hac/vehicle-models`,
          config
        );
        setModels(response.data);

        if (vehicle.id) {
          const modelExists = response.data.some(
            (m) => m.model === vehicle.model
          );

          if (!modelExists) {
            setModel('Other');
            setShowOther(true);
            setOtherModel(vehicle.model);
          } else {
            setModel(vehicle.model);
            setShowOther(false);
          }
        } else {
          setModel('');
          setShowOther(false);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };
    fetchRetailers();
    fetchModels();
  }, [token, vehicle.id, vehicle.model]);

  useEffect(() => {
    let filtered = retailers;
    if (retailerAreaCode) {
      filtered = filtered.filter(
        (retailer) => retailer.area_code === retailerAreaCode
      );
    }
    if (citySearch) {
      filtered = filtered.filter((retailer) =>
        retailer.city.toLowerCase().includes(citySearch.toLowerCase())
      );
    }
    setFilteredRetailers(filtered);
  }, [retailerAreaCode, citySearch, retailers]);

  const handleCitySearchChange = (e) => {
    setCitySearch(e.target.value);
    setRetailerCity(e.target.value);
  };

  useEffect(() => {
    if (models.length > 0 && model) {
      const modelExists = models.some((m) => m.model === model);
      if (!modelExists) {
        setShowOther(true);
      } else {
        setShowOther(false);
      }
    }
  }, [models, model]);

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);

    if (selectedModel === 'Other') {
      setShowOther(true);
    } else {
      setShowOther(false);
    }
  };

  const handleOtherModelChange = (e) => {
    setOtherModel(e.target.value);
  };

  const handleRetailerChange = (e) => {
    const selectedRetailerId = e.target.value;
    setRetailerId(selectedRetailerId);

    if (selectedRetailerId === '') {
      setRetailerAreaCode('');
      setRetailerCity('');
      return;
    }

    const selectedRetailer = filteredRetailers.find(
      (retailer) => retailer.id === parseInt(selectedRetailerId, 10)
    );

    if (selectedRetailer) {
      setRetailerAreaCode(selectedRetailer.area_code || '');
      setRetailerCity(selectedRetailer.city || '');
    } else {
      setRetailerAreaCode('');
      setRetailerCity('');
    }
  };

  const saveNewVehicle = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const newVehicle = {
      model: model === 'Other' ? otherModel : model,
      retailer_id: retailerId || undefined,
      license_plate: licensePlate || undefined,
      purchase_date: purchaseDate
        ? dayjs(purchaseDate).format('YYYY')
        : undefined,
      vista_number: vistaNumber || undefined,
      vin: vin || undefined,
      id: id || undefined,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    apiClient
      .post(
        `${process.env.REACT_APP_API_URL}/retailer/hac-vehicle/?hacId=${hacId}`,
        newVehicle,
        config
      )
      .then((response) => {
        navigate(`/retailer/vehicles/${hacId}`, {
          state: { openSnackbar: true },
        });
      })
      .catch((error) => {
        console.error('Error saving vehicle:', error);
      });
  };

  const cancelNewVehicle = () => {
    navigate(`/retailer/vehicles/${hacId}`);
  };

  return (
    <>
      {
        (token && (role === 'retailer' || role === 'retailerGM')) ? (
        <div className="App">
          <Header />
          <main>
            <section className="form">
              <div className="inner ">
                <main>
                  <Link
                    to={`/retailer/vehicles/${hacId}`}
                    className="back-button"
                  >
                    <span className="icon-chevron-left"></span>{' '}
                    {t('common-back')}
                  </Link>
                  {!id && (
                    <>
                      <h4>{t('common-new-vehicle')}</h4>
                      <p>
                        {t(
                          'Fill in the following information and click on the save button to add a new vehicle.'
                        )}
                      </p>
                    </>
                  )}
                  <div className="form">
                    <form className="edit-vehicle">
                      <h5>{t('common-vehicle-details')}</h5>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={12} md={6}>
                          <div className="form-control">
                            <label htmlFor="model">{t('common-model')}</label>
                            <select
                              name="model"
                              id="model"
                              className="normal"
                              value={model}
                              onChange={handleModelChange}
                            >
                              <option value="" key="0">
                                {t('common-select-model')}
                              </option>
                              {models.map((m) => (
                                <option key={m.id} value={m.model}>
                                  {m.model}
                                </option>
                              ))}
                              <option value="Other">{t('common-other')}</option>
                            </select>
                            {errors.model && (
                              <p className="error">{errors.model}</p>
                            )}
                          </div>
                        </Grid>
                        {showOther && (
                          <Grid xs={12} sm={12} md={6}>
                            <div className="form-control">
                              <label htmlFor="other">{t('common-other')}</label>
                              <input
                                type="text"
                                name="other"
                                id="other"
                                value={otherModel}
                                onChange={handleOtherModelChange}
                              />
                              {errors.other && (
                                <p className="error">{errors.other}</p>
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={12} md={6}>
                          <div className="form-control">
                            <label htmlFor="license_plate">
                              {t('common-license-plate')}
                            </label>
                            <input
                              type="text"
                              name="license_plate"
                              id="license_plate"
                              value={licensePlate}
                              onChange={(e) => setLicensePlate(e.target.value)}
                            />
                            {errors.licensePlate && (
                              <p className="error">{errors.licensePlate}</p>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                          <div className="form-control">
                            <label htmlFor="vin">{t('common-vin')}</label>
                            <input
                              type="text"
                              name="vin"
                              id="vin"
                              value={vin}
                              onChange={(e) => setVin(e.target.value)}
                            />
                            {errors.vin && (
                              <p className="error">{errors.vin}</p>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                          <div className="form-control datepicker">
                            <label htmlFor="purchaseDate">{t('common-purchase-date')}</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Controller
                                name="purchaseDate"
                                defaultValue={purchaseDate}
                                control={control}
                                render={({ field }) => (
                                  <DateField
                                    {...field}
                                    format="YYYY"
                                    onChange={(date) => {
                                      const isValidDate = dayjs(date).isValid();
                                      if (isValidDate) {
                                        const formattedDate =
                                          dayjs(date).format('YYYY-MM-DD');
                                        setPurchaseDate(formattedDate);
                                        field.onChange(formattedDate);
                                      } else {
                                        setPurchaseDate('');
                                        field.onChange('');
                                      }
                                    }}
                                    value={
                                      field.value
                                        ? dayjs(field.value, 'YYYY')
                                        : null
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Grid>
                      </Grid>
                      <p>{t('common-retailer-details')}</p>
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={12} md={6}>
                          <div className="form-control">
                            <label htmlFor="retailer_area_code">
                              {t('common-country')}
                            </label>
                            <select
                              name="retailer_area_code"
                              id="retailer_area_code"
                              value={retailerAreaCode}
                              onChange={(e) =>
                                setRetailerAreaCode(e.target.value)
                              }
                              className="normal"
                            >
                              <option value="">
                                {t('common-select-country')}
                              </option>
                              {uniqueCountries.map((countryCode) => (
                                <option key={countryCode} value={countryCode}>
                                  {t(`countries-${countryCode}`)}
                                </option>
                              ))}
                            </select>
                            {errors.retailerAreaCode && (
                              <p className="error">{errors.retailerAreaCode}</p>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                          <div className="form-control">
                            <label htmlFor="retailer_city">
                              {t('common-city')}
                            </label>
                            <input
                              type="text"
                              id="retailer_city"
                              name="retailer_city"
                              value={retailerCity}
                              onChange={handleCitySearchChange}
                            />
                            {errors.retailerCity && (
                              <p className="error">{errors.retailerCity}</p>
                            )}
                          </div>
                        </Grid>
                        <Grid xs={12} sm={12} md={12}>
                          <div className="form-control">
                            <label htmlFor="retailer_name">
                              {t('common-retailer-name')}
                            </label>
                            <select
                              name="retailer_name"
                              id="retailer_name"
                              value={retailerId}
                              onChange={handleRetailerChange}
                              className="normal"
                            >
                              <option value="">
                                {t('common-select-retailer')}
                              </option>
                              {filteredRetailers.map((retailer) => (
                                <option key={retailer.id} value={retailer.id}>
                                  {retailer.name}
                                </option>
                              ))}
                            </select>
                            {errors.retailerId && (
                              <p className="error">{errors.retailerId}</p>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <div className="actions">
                        <button
                          className="btn secondary"
                          onClick={cancelNewVehicle}
                        >
                          {t('common-cancel')}
                        </button>
                        <button className="btn" onClick={saveNewVehicle}>
                          {t('hac-my-profile-vehicles-edit-action')}
                        </button>
                      </div>
                    </form>
                  </div>
                </main>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default HacEditVehicle;
