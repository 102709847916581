import './preferences.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import { Link } from 'react-router-dom';
import Preferences from '../../../organisms/role-hac/hac-preferences/preferences-hac';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { Alert, Snackbar } from "@mui/material";
import ImgPreferences1280category1 from "../../../../assets/img/preferences/preferences1280-1.jpg"
import ImgPreferences767category1 from "../../../../assets/img/preferences/preferences767-1.jpg"
import ImgPreferences1280category2 from "../../../../assets/img/preferences/preferences1280-2.jpg"
import ImgPreferences767category2 from "../../../../assets/img/preferences/preferences767-2.jpg"
import ImgPreferences1280category3 from "../../../../assets/img/preferences/preferences1280-3.jpg"
import ImgPreferences767category3 from "../../../../assets/img/preferences/preferences767-3.jpg"
import ImgPreferences1280category4 from "../../../../assets/img/preferences/preferences1280-4.jpg"
import ImgPreferences767category4 from "../../../../assets/img/preferences/preferences767-4.jpg"
import ImgPreferences1280category5 from "../../../../assets/img/preferences/preferences1280-5.jpg"
import ImgPreferences767category5 from "../../../../assets/img/preferences/preferences767-5.jpg"
import ImgPreferences1280category6 from "../../../../assets/img/preferences/preferences1280-6.jpg"
import ImgPreferences767category6 from "../../../../assets/img/preferences/preferences767-6.jpg"
import ImgPreferences1280category7 from "../../../../assets/img/preferences/preferences1280-7.jpg"
import ImgPreferences767category7 from "../../../../assets/img/preferences/preferences767-7.jpg"
import ImgPreferences1280category8 from "../../../../assets/img/preferences/preferences1280-8.jpg"
import ImgPreferences767category8 from "../../../../assets/img/preferences/preferences767-8.jpg"

const HacPreferences = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const decodedToken = decodeToken(token);
  const role = decodedToken.role;
  const hacId = decodedToken.id;

  /* Ancho de pantalla */
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const cat = params.get('cat');
  const categories = useMemo(
    () => location.state?.categories || [],
    [location.state?.categories]
  );

  const [activeItem, setActiveItem] = useState(null);
  const [preferencesCount, setPreferencesCount] = useState({});

  useEffect(() => {
    if (cat) {
      const index = categories.findIndex((item) => item.id === cat);
      if (index !== -1) {
        setActiveItem(index);
      }
    }
  }, [cat, categories]);

  useEffect(() => {
    const fetchPreferencesCounts = async () => {
      try {
        const counts = {};
        for (const category of categories) {
          const url = `${process.env.REACT_APP_API_URL}/hac/category-preferences/${hacId}?categoryId=${category.id}`;
          const response = await apiClient.get(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          counts[category.id] = response.data.length;
        }
        setPreferencesCount(counts);
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    if (categories.length > 0) {
      fetchPreferencesCounts();
    }
  }, [categories, token, hacId, t]);

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Mapeo de imágenes para categorías
  const images = {
    'category-1': {
      img1280: ImgPreferences1280category1,
      img767: ImgPreferences767category1
    },
    'category-2': {
      img1280: ImgPreferences1280category2,
      img767: ImgPreferences767category2
    },
    'category-3': {
      img1280: ImgPreferences1280category3,
      img767: ImgPreferences767category3
    },
    'category-4': {
      img1280: ImgPreferences1280category4,
      img767: ImgPreferences767category4
    },
    'category-5': {
      img1280: ImgPreferences1280category5,
      img767: ImgPreferences767category5
    },
    'category-6': {
      img1280: ImgPreferences1280category6,
      img767: ImgPreferences767category6
    },
    'category-7': {
      img1280: ImgPreferences1280category7,
      img767: ImgPreferences767category7
    },
    'category-8': {
      img1280: ImgPreferences1280category8,
      img767: ImgPreferences767category8
    }
  };

  // Actualización del ancho de pantalla al redimensionar
  useEffect(() => {
    const handleResize = () => {
      setAnchoPantalla(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Limpia el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header />
          <main className="preferences">
            <Wadsworth />
            <section>
              <div className="inner menu">
                <aside className="lateral-menu">
                  <h2>{t('hac-menu-preferences-title')}</h2>
                  <ul>
                    {Array.isArray(categories) &&
                      categories.map((item, index) => (
                        <li
                          key={item.id}
                          className={activeItem === index ? 'on' : ''}
                          onClick={() => handleItemClick(index)}
                        >
                          <Link
                            to={`/hac/preferences?cat=${item.id}`}
                            state={{ categories }}
                          >
                            {t(`hac-preferences-${item.id}`)}{' '}
                            ({preferencesCount[item.id] || 0})
                          </Link>
                        </li>
                      ))}
                  </ul>
                </aside>
                <main className="preferences">
                  <div className="header-img">
                    {images[cat] ? (
                      anchoPantalla < 768 ? (
                        <img src={images[cat].img767} alt="" />
                      ) : (
                        <img src={images[cat].img1280} alt="" />
                      )
                    ) : null}
                  </div>
                  <h4>{t(`hac-preferences-${cat}`)}</h4>
                  <p>{t(`hac-preferences-${cat}-text`)}</p>
                  <Preferences cat={cat} />
                </main>
              </div>
            </section>
          </main>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>

      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default HacPreferences;
