import React, { useState } from 'react';
import { Collapse } from '@mui/material';
import './settings.scss';
import Header from '../../../organisms/header/header.js';
import Grid from '@mui/material/Unstable_Grid2';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {Link} from "react-router-dom";

function ShippingAdminSettings() {
  const { t } = useTranslation();

  const [expandedSections, setExpandedSections] = useState({
    language: false,
    contact: false,
    password: false,
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const languages = [
    { name: t('languages-de-AT'), code: 'de-AT' },
    { name: t('languages-de-CH'), code: 'de-CH' },
    { name: t('languages-de-DE'), code: 'de-DE' },
    { name: t('languages-nl-NL'), code: 'du' },
    { name: t('languages-en'), code: 'en' },
    { name: t('languages-es'), code: 'es' },
    { name: t('languages-fr-FR'), code: 'fr-FR' },
    { name: t('languages-fr-CH'), code: 'fr-CH' },
    { name: t('languages-fr-BE'), code: 'fr-BE' },
    { name: t('languages-it-IT'), code: 'it-IT' },
    { name: t('languages-it-CH'), code: 'it-CH' },
    { name: t('languages-pt'), code: 'pt' },
    { name: t('languages-sk'), code: 'sk' },
    { name: t('languages-tr'), code: 'tr' },
    { name: t('languages-cz'), code: 'cz' },
  ];
  const [openSnackbarLanguages, setOpenSnackbarLanguages] = useState(false);
  const [openSnackbarContact, setOpenSnackbarContact] = useState(false);

  const handleCloseSnackbarLanguages = () => {
    setOpenSnackbarLanguages(false);
  };
  const handleCloseSnackbarContact = () => {
    setOpenSnackbarLanguages(false);
  };

  const saveLanguage = async () => {
    setOpenSnackbarLanguages(true);
  };
  const saveContactInfo = async () => {
    setOpenSnackbarContact(true);
  };

  return (
    <div className="App">
      <Header />
      <main>
        <section className="settings">
          <div className="inner">
            <Link to="/shipping-admin/main/" className="back-button">
              <span className="icon-chevron-left"></span>{' '}
              {t('common-back')}
            </Link>

            <h2>{t('settings-title')}</h2>
            <p>{t('settings-text')}</p>

            {/* language */}
            <div className="title" onClick={() => toggleSection('language')}>
              <h5>{t('settings-setting1')}</h5>
              <button className="btn text">
                {expandedSections.language ? (
                  <span className="icon-minus"></span>
                ) : (
                  <span className="icon-plus"></span>
                )}
              </button>
            </div>
            <Collapse in={expandedSections.language}>
              <div className="form-control">
                <select name="area_code" id="country" className="normal">
                  {languages.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="actions">
                <button className="btn" onClick={saveLanguage}>
                  {t('common-save-changes')}
                </button>
              </div>
            </Collapse>

            {/* contact */}
            <div className="title" onClick={() => toggleSection('contact')}>
              <h5>{t('settings-setting2')}</h5>
              <button className="btn text">
                {expandedSections.contact ? (
                  <span className="icon-minus"></span>
                ) : (
                  <span className="icon-plus"></span>
                )}
              </button>
            </div>
            <Collapse in={expandedSections.contact}>
              <div className="form-control">
                <label htmlFor="phone">{t('common-phone')}</label>
                <Grid container spacing={1}>
                  <Grid xs={2} sm={1} md={4}>
                    <div className="form-control">
                      <input
                        type="text"
                        id="prefix_phone"
                        name="prefix_phone"
                        autoComplete="off"
                        placeholder="+xx"
                      />
                    </div>
                  </Grid>
                  <Grid xs={4} sm={1} md={8}>
                    <div className="form-control">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        autoComplete="off"
                        placeholder="xxxxxxxxx"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="actions">
                <button className="btn" onClick={saveContactInfo}>
                  {t('common-save-changes')}
                </button>
              </div>
            </Collapse>

            {/* resetear password */}
            <div className="title" onClick={() => toggleSection('password')}>
              <h5>{t('settings-setting4')}</h5>
              <button className="btn text">
                {expandedSections.password ? (
                  <span className="icon-minus"></span>
                ) : (
                  <span className="icon-plus"></span>
                )}
              </button>
            </div>
            <Collapse in={expandedSections.password}>
              <p>{t('settings-setting4-text')}</p>
              <div className="actions">
                <button className="btn">{t('common-save-password')}</button>
              </div>
            </Collapse>
          </div>
        </section>
      </main>
      <Snackbar
        open={openSnackbarLanguages}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarLanguages}
        message={t('settings-setting1-feedback')}
      />
      <Snackbar
        open={openSnackbarContact}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarContact}
        message={t('settings-setting2-feedback')}
      />
    </div>
  );
}

export default ShippingAdminSettings;
