import React, { useEffect, useState } from 'react';
import { Alert, Collapse, Snackbar } from '@mui/material';
import './settings.scss';
import Header from '../../../organisms/header/header.js';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import { useTranslation } from 'react-i18next';
// import { jwtDecode } from 'jwt-decode';
import apiClient from '../../../../utils/apiClient';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

function PmaSettings() {
  const { t } = useTranslation();

  const [userLanguage, setUserLanguage] = useState('');
  const [country, setCountry] = useState('');

  const [consents, setConsents] = useState({
    email: false,
    phone: false,
    sms: false,
    post: false,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token') || null;

        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        // const decodedToken = jwtDecode(token);
        // const userId = decodedToken.id;

        const basicInfoUrl = `${process.env.REACT_APP_API_URL}/hac/basic-info`;
        const consentsUrl = `${process.env.REACT_APP_API_URL}/hac/consents`;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const basicInfoResponse = await apiClient.get(basicInfoUrl, config);
        const userLanguage = basicInfoResponse.data.preferredLanguage;
        setUserLanguage(userLanguage);
        setCountry(basicInfoResponse.data.area_code);

        const consentsResponse = await apiClient.get(consentsUrl, config);
        setConsents(consentsResponse.data);
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    fetchData();
  }, [t]);

  const [expandedSections, setExpandedSections] = useState({
    language: false,
    contact: false,
    password: false,
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const languages = [
    { name: t('languages-de-AT'), code: 'de-AT' },
    { name: t('languages-de-CH'), code: 'de-CH' },
    { name: t('languages-de-DE'), code: 'de-DE' },
    { name: t('languages-nl-NL'), code: 'du' },
    { name: t('languages-en'), code: 'en' },
    { name: t('languages-es'), code: 'es' },
    { name: t('languages-fr-FR'), code: 'fr-FR' },
    { name: t('languages-fr-CH'), code: 'fr-CH' },
    { name: t('languages-fr-BE'), code: 'fr-BE' },
    { name: t('languages-it-IT'), code: 'it-IT' },
    { name: t('languages-it-CH'), code: 'it-CH' },
    { name: t('languages-pt'), code: 'pt' },
    { name: t('languages-sk'), code: 'sk' },
    { name: t('languages-tr'), code: 'tr' },
    { name: t('languages-cz'), code: 'cz' },
  ];

  const [openSnackbarLanguages, setOpenSnackbarLanguages] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleCloseSnackbarLanguages = () => {
    setOpenSnackbarLanguages(false);
  };

  const saveLanguage = async () => {
    const token = sessionStorage.getItem('token') || null;
    try {
      const url = `${process.env.REACT_APP_API_URL}/hac/personal-settings`;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const data = {
        language: userLanguage,
      };

      await apiClient.patch(url, data, config);
      i18n.changeLanguage(userLanguage);
      setSnackbarMessage(t('settings-setting1-feedback'));
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setSnackbarMessage(t('settings-setting1-error'));
    }

    setOpenSnackbarLanguages(true);
  };

  const [openSnackbarContact, setOpenSnackbarContact] = useState(false);
  const handleCloseSnackbarConsent = () => {
    setOpenSnackbarLanguages(false);
  };

  /*const saveConsentInfo = async () => {
    const token = sessionStorage.getItem('token') || null;

    delete consents.id;

    if (!token) {
      console.error('No se encontró ningún token en sessionStorage.');
      setSnackbarMessage(t('settings-setting2-error'));
      setOpenSnackbarContact(true);
      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/hac/consents`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      await apiClient.post(url, consents, config);
      setSnackbarMessage(t('settings-setting2-feedback'));
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setSnackbarMessage(t('settings-setting2-error'));
    }

    setOpenSnackbarContact(true);
  };*/

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'all') {
      setConsents((prevConsents) => ({
        ...prevConsents,
        email: checked,
        post: checked,
        phone: checked,
        sms: checked,
      }));
    } else if (name === 'phoneSms') {
      setConsents((prevConsents) => ({
        ...prevConsents,
        phone: checked,
        sms: checked,
      }));
    } else {
      setConsents((prevConsents) => ({
        ...prevConsents,
        [name]: checked,
      }));
    }
  };

/*  const renderWelcome = () => {
    switch (country) {
      case 'DE':
        return (
          <>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.email}
                name="email"
                onChange={handleCheckboxChange}
                label={t('common-email')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.sms}
                name="sms"
                onChange={handleCheckboxChange}
                label={t('common-sms')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.phone}
                name="phone"
                onChange={handleCheckboxChange}
                label={t('common-phone')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.post}
                name="post"
                onChange={handleCheckboxChange}
                label={t('common-post')}
              />
            </div>
          </>
        );
      case 'FR':
        return (
          <>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.email}
                name="email"
                onChange={handleCheckboxChange}
                label={t('common-email')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.sms}
                name="sms"
                onChange={handleCheckboxChange}
                label={t('common-sms')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.phone}
                name="phone"
                onChange={handleCheckboxChange}
                label={t('common-phone')}
              />
            </div>
          </>
        );
      case 'AT':
      case 'CZ':
      case 'SK':
        return (
          <>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.email}
                name="email"
                onChange={handleCheckboxChange}
                label={t('common-email')}
              />
            </div>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={consents.phone && consents.sms}
                name="phoneSms"
                onChange={handleCheckboxChange}
                label={t('common-phone-sms')}
              />
            </div>
          </>
        );
      case 'ES':
      case 'IT':
      case 'PT':
      default:
        return (
          <>
            <div className="form-control">
              <FormControlLabel
                control={<Checkbox />}
                checked={
                  consents.phone &&
                  consents.sms &&
                  consents.email &&
                  consents.post
                }
                name="all"
                onChange={handleCheckboxChange}
                label={t('settings-setting3-accept-all')}
              />
            </div>
          </>
        );
    }
  };*/

  return (
    <div className="App">
      <Header />
      <main>
        <Wadsworth />
        <section className="settings">
          <div className="inner">
            <h2>{t('settings-title')}</h2>
            <p>{t('settings-text')}</p>

            {/* language */}
            <div className="title" onClick={() => toggleSection('language')}>
              <h5>{t('settings-setting1')}</h5>
              <button className="btn text">
                {expandedSections.language ? (
                  <span className="icon-minus"></span>
                ) : (
                  <span className="icon-plus"></span>
                )}
              </button>
            </div>
            <Collapse in={expandedSections.language}>
              <div className="form-control">
                <select
                  name="area_code"
                  id="country"
                  className="normal"
                  value={userLanguage}
                  onChange={(e) => setUserLanguage(e.target.value)}
                >
                  {languages.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="actions">
                <button className="btn" onClick={saveLanguage}>
                  {t('common-save-changes')}
                </button>
              </div>
            </Collapse>

            {/* consents
            <div className="title" onClick={() => toggleSection('contact')}>
              <h3>{t('settings-setting3')}</h3>
              <button className="btn text">
                {expandedSections.contact ? (
                  <span className="icon-minus"></span>
                ) : (
                  <span className="icon-plus"></span>
                )}
              </button>
            </div>
            <Collapse in={expandedSections.contact}>
              <p>{t('settings-setting3-text')}</p>
              <div className="consents">{renderWelcome()}</div>
              <div className="actions">
                <button className="btn" onClick={saveConsentInfo}>
                  {t('common-save-changes')}
                </button>
              </div>
            </Collapse>
            */}

            {/* resetear password */}
            <div className="title" onClick={() => toggleSection('password')}>
              <h5>{t('settings-setting4')}</h5>
              <button className="btn text">
                {expandedSections.password ? (
                  <span className="icon-minus"></span>
                ) : (
                  <span className="icon-plus"></span>
                )}
              </button>
            </div>
            <Collapse in={expandedSections.password}>
              <p>{t('settings-setting4-text')}</p>
              <div className="actions">
                <Link to="/password-recovery" className="btn">
                  {t('common-change-password')}
                </Link>
              </div>
            </Collapse>
          </div>
        </section>
      </main>
      <Snackbar
        open={openSnackbarLanguages}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarLanguages}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarContact}
        autoHideDuration={3000}
        onClose={handleCloseSnackbarConsent}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PmaSettings;
