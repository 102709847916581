import './preferences-hac.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import { useParams } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';

export default function RetailerPreferencesHac() {
  const { t } = useTranslation();
  const { hacId } = useParams();
  const token = sessionStorage.getItem('token') || null;

  const [expandedItem, setExpandedItem] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = useCallback(async () => {

    try {
      const url = `${process.env.REACT_APP_API_URL}/retailer/hac-preferences/${hacId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await apiClient.get(url, config);
      setPreferences(sortDataByCategoryAndTitle(response.data));
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('alert-server-error'), error);
      }
    }
  }, [hacId, token, t]);

  function sortDataByCategoryAndTitle(data) {
    return data.sort((a, b) => {
      const categoryA = a.category_id.toLowerCase();
      const categoryB = b.category_id.toLowerCase();
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExpand = (itemName) => {
    setExpandedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  const handleClick = (event, index, item) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);

  };

  const open = (index) => Boolean(anchorEl[index]);


  return (
    <>
      {Array.isArray(preferences) && preferences.length > 0 ? (
        <ul className="hac-preferences">
          {preferences.map((item, index) => (
            <li key={index}>
              <span>
                <span
                  className="header"
                  onClick={() => handleExpand(`preferences${index}`)}
                >
                  {expandedItem === `preferences${index}` ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}
                  <span>{t(`hac-preferences-${item.category_id}`)}</span>
                  <span>{item.title}</span>
                </span>
                <span
                  aria-controls={open(index) ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open(index) ? 'true' : undefined}
                  onClick={(event) => handleClick(event, index, item)}
                >
                </span>
              </span>
              <Collapse in={expandedItem === `preferences${index}`}>
                <p>{item.value}</p>
              </Collapse>
              {/*<Menu
                className="submenu"
                id={`menu${index}`}
                anchorEl={anchorEl[index]}
                open={open(index)}
                onClose={() => handleClose(index)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose(index);
                    setEditModalOpen(true);
                  }}
                >
                  <span className="icon-edit"></span>{' '}
                  {t('hac-preferences-menu1')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeletePreference(item.id);
                    handleClose(index);
                  }}
                >
                  <span className="icon-delete"></span>{' '}
                  {t('hac-preferences-menu2')}
                </MenuItem>
              </Menu>*/}
            </li>
          ))}
        </ul>
      ) : (
        <h2>{t('hac-preferences-no-data')}</h2>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
