import './main.scss';
import React, {useEffect, useState} from 'react';
import {Link, Navigate} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import {useTranslation} from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ModalHacShowContact from '../../../organisms/role-hac/modal-hac-show-contact/modal-hac-show-contact.js';

import Gallery1 from '../../../../assets/img/landing/gallery1.jpg';
import Gallery2 from '../../../../assets/img/landing/gallery2.jpg';
import Gallery3 from '../../../../assets/img/landing/gallery3.jpg';
import Img1Benefits1280 from '../../../../assets/img/landing/benefits1-1280.jpg';
import Img2Benefits1280 from '../../../../assets/img/landing/benefits2-1280.jpg';
import Img3Benefits1280 from '../../../../assets/img/landing/benefits3-1280.jpg';
import Img4Benefits1280 from '../../../../assets/img/landing/benefits4-1280.jpg';
import Img1Benefits767 from '../../../../assets/img/landing/benefits1-767.jpg';
import Img2Benefits767 from '../../../../assets/img/landing/benefits2-767.jpg';
import Img3Benefits767 from '../../../../assets/img/landing/benefits3-767.jpg';
import Img4Benefits767 from '../../../../assets/img/landing/benefits4-767.jpg';
import Footer from "../../../organisms/footer/footer";

const MainHac = () => {
  const {t} = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const [openModal, setOpenModal] = useState(false);

  const showContact = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  /* Ancho de pantalla */
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setAnchoPantalla(window.innerWidth);
    };

    // Agregar un listener para el evento de cambio de tamaño de pantalla
    window.addEventListener('resize', handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* Slick slider react */
  const [sliderNav, setNav] = useState();
  const [sliderContent, setContent] = useState();

  const settingsNav = {
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          infinite: false,
          variableWidth: true,
          focusOnSelect: true,
          swipeToSlide: true,
          slidesToShow: 4,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-content',
        },
      },
      {
        // breakpoint para el slider de beneftis cambiar tambien en variables.scss buscando el nombre de esta variable ($custom-slider-beneficts)
        breakpoint: 1210,

        settings: {
          infinite: false,
          variableWidth: true,
          focusOnSelect: true,
          swipeToSlide: true,
          slidesToShow: 1,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-content',
        },
      },
    ],
  };
  const settingsContent = {
    responsive: [
      {
        breakpoint: 5000,
        settings: {
          infinite: false,
          focusOnSelect: true,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-nav',
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          focusOnSelect: true,
          accessibility: true,
          arrows: false,
          asNavForIndex: 'benefits-nav',
        },
      },
    ],
  };

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header/>
          <main className="landing">
            <Wadsworth/>
            <section className="hero">
              <div>
                <h1>
                  {t('hac-landing-hero-title')}
                  <small>{t('hac-landing-hero-subtitle')}</small>
                </h1>
              </div>
            </section>

            <section className="intro">
              <div className="inner">
                <h2>{t('hac-landing-intro-title')}</h2>
                <p>{t('hac-landing-intro-text')}</p>
                <Link
                  to="/hac/profile/basic-info"
                  className="btn text landing-cta-2"
                  aria-label={t('landing-cta-2')}
                >
                  <span className="icon-chevron-right"></span>
                  {t('landing-cta-2')}
                </Link>
              </div>
            </section>

            <section className="benefits dark">
              <div className="inner">
                <h2>{t('hac-landing-benefits-title')}</h2>
                <p>{t('hac-landing-benefits-text')}</p>
                <div className="img"></div>

                <Slider
                  className="benefits-nav"
                  {...settingsNav}
                  asNavFor={sliderContent}
                  ref={(slider1) => setNav(slider1)}
                >
                  <div>{t('hac-landing-benefits-tab1-tab')}</div>
                  <div>{t('hac-landing-benefits-tab2-tab')}</div>
                  <div>{t('hac-landing-benefits-tab3-tab')}</div>
                  <div>{t('hac-landing-benefits-tab4-tab')}</div>
                </Slider>
                <Slider
                  className="benefits-content"
                  {...settingsContent}
                  asNavFor={sliderNav}
                  ref={(slider2) => setContent(slider2)}
                >
                  <div className="content">
                    <div>
                      {anchoPantalla < 768 ? (
                        <img src={Img1Benefits767} alt=""/>
                      ) : (
                        <img src={Img1Benefits1280} alt=""/>
                      )}
                    </div>
                    <div>
                      <h5>{t('hac-landing-benefits-tab1-title')}</h5>
                      <div className="text">
                        <p dangerouslySetInnerHTML={{__html: t('hac-landing-benefits-tab1-text')}}></p>
                        <button className="btn fit" onClick={showContact}>
                          <span className="text">{t('landing-cta-1')}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    <div>
                      {anchoPantalla < 768 ? (
                        <img src={Img2Benefits767} alt=""/>
                      ) : (
                        <img src={Img2Benefits1280} alt=""/>
                      )}
                    </div>
                    <div>
                      <h5>{t('hac-landing-benefits-tab2-title')}</h5>
                      <div className="text">
                        <p dangerouslySetInnerHTML={{__html: t('hac-landing-benefits-tab2-text')}}></p>
                        <button className="btn fit" onClick={showContact}>
                          <span className="text">{t('landing-cta-1')}</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    <div>
                      {anchoPantalla < 768 ? (
                        <img src={Img3Benefits767} alt=""/>
                      ) : (
                        <img src={Img3Benefits1280} alt=""/>
                      )}
                    </div>
                    <div>
                      <h5>{t('hac-landing-benefits-tab3-title')}</h5>
                      <div className="text">
                        <p dangerouslySetInnerHTML={{__html: t('hac-landing-benefits-tab3-text')}}></p>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    <div>
                      {anchoPantalla < 768 ? (
                        <img src={Img4Benefits767} alt=""/>
                      ) : (
                        <img src={Img4Benefits1280} alt=""/>
                      )}
                    </div>
                    <div>
                      <h5>{t('hac-landing-benefits-tab4-title')}</h5>
                      <div className="text">
                        <p>
                          <strong>
                            {t('hac-landing-benefits-tab4-subTitle')}
                          </strong>
                        </p>

                        <p>
                          <p dangerouslySetInnerHTML={{__html: t('hac-landing-benefits-tab4-text')}}></p>
                        </p>
                        <button className="btn fit" onClick={showContact}>
                          <span className="text">{t('landing-cta-1')}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </section>

            <section className="gallery">
              <div className="inner">
                <h2>{t('hac-landing-gallery-title')}</h2>
                <p>{t('hac-landing-gallery-text')}</p>


                <ul>
                  <li>
                    <img src={Gallery1} alt=""/>
                    <p>
                      <strong>{t('hac-landing-gallery-title-item1')}</strong>
                      {t('hac-landing-gallery-text-item1')}
                    </p>
                  </li>
                  <li>
                    <img src={Gallery2} alt=""/>
                    <p>
                      <strong>{t('hac-landing-gallery-title-item2')}</strong>
                      {t('hac-landing-gallery-text-item2')}
                    </p>
                  </li>
                  <li>
                    <img src={Gallery3} alt=""/>
                    <p>
                      <strong>{t('hac-landing-gallery-title-item3')}</strong>
                      {t('hac-landing-gallery-text-item3')}
                    </p>
                  </li>
                </ul>

                <p>
                  <a href="/hac/catalog" className="btn landing-cta-4"><span className="text">{t('landing-cta-4')}</span></a>
                </p>

              </div>
            </section>
          </main>
          <Footer/>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
      <ModalHacShowContact
        open={openModal}
        handleClose={handleCloseModal}
        className="modal-contact"
      />
    </>
  );
};

export default MainHac;
