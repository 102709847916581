import './assigned-pmas.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import axios from 'axios';
import ModalReassignPma from '../../../organisms/role-admin/modal-reassign-pma/modal-reassign-pma';
import ModalEditPma from '../../../organisms/role-admin/modal-edit-pma/modal-edit-pma';
import { Alert, Snackbar } from '@mui/material';

function AssignedPmas() {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const { hacId } = useParams();

  const [contactInfo, setContactInfo] = useState(null);
  const [modalOpenReassignPma, setModalOpenReassignPma] = useState(false);
  const [currentPma, setCurrentPma] = useState(null);
  const [pmaType, setPmaType] = useState(1);
  const [modalOpenEditPma, setModalOpenEditPma] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/pma/contact-info/hac/${hacId}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await axios.get(url, config);
        if (response.status === 200) {
          setContactInfo(response.data);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-server-error'), error);
        }
      }
    };

    fetchData();
  }, [hacId, token, t]);

  const openModalReassignPma = (pma, type) => {
    setCurrentPma(pma);
    setPmaType(type);
    setModalOpenReassignPma(true);
  };

  const closeModalReassignPma = () => {
    setModalOpenReassignPma(false);
    setCurrentPma(null);
  };

  const openModalEditPma = (pma) => {
    setCurrentPma(pma);
    setModalOpenEditPma(true);
  };

  const closeModalEditPma = () => {
    setModalOpenEditPma(false);
    setCurrentPma(null);
  };

  return (
    <>
      {token &&
      (role === 'admin' ||
        role === 'marketAdmin' ||
        role === 'countryAdmin') ? (
        <div className="App">
          <Header />
          <main>
            <section className="contact-info assigned-pmas">
              <div className="inner">
                <Link to="/admin/main/hac" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                <h2>{t('pma-info-title')}</h2>
                {contactInfo ? (
                  <>
                    <h5>{t('pma-info-title-pma1')}</h5>
                    <div className="person">
                      <>
                        {contactInfo.first_pma && (
                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>
                                {contactInfo.first_pma.name}{' '}
                                {contactInfo.first_pma.last_name}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>{contactInfo.first_pma.email}</dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.first_pma.prefix_phone}{' '}
                                {contactInfo.first_pma.phone}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-country')}</dt>
                              <dd>
                                {t(
                                  `countries-${contactInfo.first_pma.area_code}`
                                ) || 'N/A'}
                              </dd>
                            </div>
                          </dl>
                        )}
                        <div className="actions">
                          {contactInfo.first_pma && (
                            <button
                              className="btn secondary"
                              onClick={() =>
                                openModalEditPma(contactInfo.first_pma)
                              }
                            >
                              {t('pma-info-action1')}
                            </button>
                          )}
                          <button
                            className="btn secondary"
                            onClick={() =>
                              openModalReassignPma(contactInfo.first_pma, 1)
                            }
                          >
                            {t('pma-info-action2')}
                          </button>
                        </div>
                      </>
                    </div>

                    <h5>{t('pma-info-title-pma2')}</h5>
                    <div className="person">
                      <>
                        {contactInfo.second_pma && (
                          <dl>
                            <div>
                              <dt>{t('common-name')}</dt>
                              <dd>
                                {contactInfo.second_pma.name || 'N/A'}{' '}
                                {contactInfo.second_pma.last_name || ''}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-email')}</dt>
                              <dd>{contactInfo.second_pma.email || 'N/A'}</dd>
                            </div>
                            <div>
                              <dt>{t('common-phone')}</dt>
                              <dd>
                                {contactInfo.second_pma.prefix_phone || 'N/A'}{' '}
                                {contactInfo.second_pma.phone || ''}
                              </dd>
                            </div>
                            <div>
                              <dt>{t('common-country')}</dt>
                              <dd>
                                {t(
                                  `countries-${contactInfo.second_pma.area_code}`
                                ) || 'N/A'}
                              </dd>
                            </div>
                          </dl>
                        )}
                        <div className="actions">
                          {contactInfo.first_pma && (
                            <button
                              className="btn secondary"
                              onClick={() =>
                                openModalEditPma(contactInfo.second_pma)
                              }
                            >
                              {t('pma-info-action1')}
                            </button>
                          )}
                          <button
                            className="btn secondary"
                            onClick={() =>
                              openModalReassignPma(contactInfo.second_pma, 2)
                            }
                          >
                            {t('pma-info-action2')}
                          </button>
                        </div>
                      </>
                    </div>
                  </>
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            </section>
          </main>
          <ModalReassignPma
            open={modalOpenReassignPma}
            onClose={closeModalReassignPma}
            hacId={hacId}
            currentPma={currentPma}
            pmaType={pmaType}
          />
          <ModalEditPma
            open={modalOpenEditPma}
            onClose={closeModalEditPma}
            currentPma={currentPma}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default AssignedPmas;
