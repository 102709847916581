import './experience.scss';
import React, {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import {useTranslation} from 'react-i18next';
import Footer from "../../../organisms/footer/footer";
import ModalHacShowContact from "../../../organisms/role-hac/modal-hac-show-contact/modal-hac-show-contact";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HacExperience = () => {

  window.scroll(0, 0);

  const {t} = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const location = useLocation();

  const event = location.state?.event || "";

  const [openModal, setOpenModal] = useState(false);

  const showContact = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Generar rutas dinámicas basadas en el valor de 'event'
  let hero, p1, p2, slider1, slider2, slider3;

  try {
    hero = require(`../../../../assets/img/experiences/${event}-hero.jpg`);
  } catch (err) {
    console.warn(`Image for hero-${event} not found`);
  }

  try {
    p1 = require(`../../../../assets/img/experiences/${event}-p1.jpg`);
  } catch (err) {
    console.warn(`Image for p1-${event} not found`);
  }

  try {
    p2 = require(`../../../../assets/img/experiences/${event}-p2.jpg`);
  } catch (err) {
    console.warn(`Image for p2-${event} not found`);
  }

  try {
    slider1 = require(`../../../../assets/img/experiences/${event}-video1.jpg`);
  } catch (err) {
    console.warn(`Image for video1-${event} not found`);
  }

  try {
    slider2 = require(`../../../../assets/img/experiences/${event}-video2.jpg`);
  } catch (err) {
    console.warn(`Image for video2-${event} not found`);
  }

  try {
    slider3 = require(`../../../../assets/img/experiences/${event}-video3.jpg`);
  } catch (err) {
    console.warn(`Image for video3-${event} not found`);
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header/>
          <main className="experience">
            <Wadsworth/>
            <section className="hero" style={{backgroundImage: hero ? `url(${hero})` : 'none'}}></section>
            <section className="title dark">
              <h1>
                {t(`experience-hero-title-${event}`)}
                <small>{t(`experience-hero-subtitle-${event}`)}</small>
              </h1>
            </section>
            <section className="info">
              <div className="inner">
                <div className="date">{t(`experience-date-${event}`)}</div>
                <div className="intro">
                  {t(`experience-intro-title-${event}`) !== `experience-intro-title-${event}` && (
                    <h2>{t(`experience-intro-title-${event}`)}</h2>
                  )}
                  <p>{t(`experience-info-intro-${event}`)}</p>
                </div>
                {p1 && (
                  <div className="p1">
                    <div className="img">
                      <img src={p1} alt=""/>
                    </div>
                    <div className="text">
                      <p dangerouslySetInnerHTML={{__html: t(`experience-block1-text-${event}`)}}></p>
                    </div>
                  </div>
                )}
                {p2 && t(`experience-block2-text-${event}`) !== `experience-block2-text-${event}` && (
                  <div className="p2">
                    <div className="img">
                      <img src={p2} alt=""/>
                    </div>
                    <div className="text">
                      <p dangerouslySetInnerHTML={{__html: t(`experience-block2-text-${event}`)}}></p>
                    </div>
                  </div>
                )}
                <div>
                  <p dangerouslySetInnerHTML={{__html: t(`experience-ending-${event}`)}}></p>
                </div>
              </div>
            </section>
            <section>
              <div className="inner">
                <Slider {...settings}>
                  {slider1 && (
                    <div>
                      <img src={slider1} alt=""/>
                    </div>
                  )}
                  {slider2 && (
                    <div>
                      <img src={slider2} alt=""/>
                    </div>
                  )}
                  {slider3 && (
                    <div>
                      <img src={slider3} alt=""/>
                    </div>
                  )}
                </Slider>
              </div>
            </section>
            <footer className="footer-experience">
              <div className="inner">
                <h2>{t('experience-footer-title')}</h2>
                <p>{t('experience-footer-text')}</p>
                <p>
                  <button className="btn fit" onClick={showContact}>
                    <span className="text">{t('common-contact-assistant')}</span>
                  </button>
                </p>
              </div>
            </footer>
          </main>
          <ModalHacShowContact
            open={openModal}
            handleClose={handleCloseModal}
            className="modal-contact"
          />
          <Footer/>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
};

export default HacExperience;
